import { Link, useParams, useSearchParams } from 'react-router-dom';
import React, { useRef, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Button, Card, Space, Typography } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { ToWords } from 'to-words';
import moment from 'moment';

import { DetailPage } from 'common/ui/AdminCRUD/page';
import userApiCall from 'apiCalls/user';
import statementApiCall from 'apiCalls/userStatement';
import businessApiCall from 'apiCalls/business';
import { useFetchApiGet } from 'common/reduxutils';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import DataTable from 'common/ui/DataTable';
import { formatDate } from 'utils/dateUtils';
import { formatCurrency } from 'utils/formatAmount';
import { formatFormalText } from 'utils/text';

const { Title, Text } = Typography;

const toWords = new ToWords({
  localeCode: 'en-US',
  converterOptions: {
    currency: false,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: {
      // can be used to override defaults for the selected locale
      name: 'ringgit',
      plural: 'ringgits',
      symbol: 'RM',
    },
  },
});

const StatementItemDetail = ({ business_id, item: debtor }) => {
  const {
    data: companyInfo,
    load: fetchCompanyInfo,
    isLoading: isLoadingCompanyInfo,
  } = useFetchApiGet(businessApiCall.detail, { resourceName: 'item' });

  useEffect(() => {
    fetchCompanyInfo({ id: business_id });
  }, [business_id]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Statement',
  });

  const urlParams = useParams();
  const params = { business_id, status__in: '1001,1002' };
  const [baseSearchParams] = useSearchParams();
  const searchParams = Object.fromEntries(baseSearchParams);
  const payload = { ...urlParams, ...searchParams, ...params };

  const { data = {}, isLoading: isLoadingData } = useQuery({
    queryKey: [statementApiCall.list.queryKey, payload],
    queryFn: () => statementApiCall.list.queryFn(payload),
  });
  const { items = [], paging, meta: metaData, stats } = data;

  const isLoading = isLoadingCompanyInfo || isLoadingData;
  const statementDate =
    searchParams['date__gte'] || searchParams['date__lte']
      ? [
          formatDate(searchParams['date__gte']) || 'Beginning',
          formatDate(searchParams['date__lte']) || 'Present',
        ].join(' - ')
      : 'All';
  const currentBalance = items[0]?.balance;
  const dataSource = items.toReversed();

  const now = moment();
  const balanceSummary = [
    {
      current: currentBalance,
      _1_month:
        items.filter(item =>
          moment(item.date).isSame(now.clone().add(-1, 'month'), 'month')
        )[0]?.balance || 0,
      _2_month:
        items.filter(item =>
          moment(item.date).isSame(now.clone().add(-2, 'month'), 'month')
        )[0]?.balance || 0,
      _3_month:
        items.filter(item =>
          moment(item.date).isSame(now.clone().add(-3, 'month'), 'month')
        )[0]?.balance || 0,
      _4_month:
        items.filter(item =>
          moment(item.date).isSame(now.clone().add(-4, 'month'), 'month')
        )[0]?.balance || 0,
    },
  ];

  return (
    <>
      <Card
        title={<Title level={3}>Statement</Title>}
        extra={
          <Space>
            <Button>
              <Link onClick={handlePrint}>Print / Download</Link>
            </Button>
          </Space>
        }
        className="invoices"
        ref={componentRef}
      >
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="p-10">
            <div className="hidden print:!block line-clamp-2">
              <b className="uppercase">Statement</b>
            </div>
            <div className="m-auto">
              <div className="leading-3">
                <div>
                  <p>
                    <strong>
                      {companyInfo.company_name}
                      {companyInfo.registration_number && (
                        <> ({companyInfo.registration_number})</>
                      )}
                    </strong>
                  </p>
                  <p>{companyInfo.address1}</p>
                  <p>{companyInfo.address2}</p>
                  <p>{companyInfo.address3}</p>
                  {companyInfo.phone && <p>Tel: {companyInfo.phone}</p>}
                </div>

                <hr className="my-5" />

                <div>
                  <div className="grid grid-cols-2 gap-2">
                    <div>
                      <div className="grid grid-cols-3 gap-2">
                        <div>
                          <p>Debtor ID:</p>
                          <p>Debtor:</p>
                          <p>Address:</p>
                        </div>
                        <div className="col-span-2">
                          <p>{debtor.debtor_code}</p>
                          <p>{debtor.name}</p>
                          <p>
                            <Text>
                              {`${debtor.address1}, ${debtor.address2}, ${debtor.address3}`}
                            </Text>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="grid grid-cols-3 gap-2">
                        <div>
                          <p>Statement Date:</p>
                          <p>Terms:</p>
                          <p>Page:</p>
                        </div>
                        <div className="col-span-2">
                          <p>{statementDate}</p>
                          <p>{''}</p>
                          <p>{}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <DataTable
                  rowKey="id"
                  columns={[
                    {
                      title: 'Date',
                      dataIndex: 'date',
                      render: value => formatDate(value),
                    },
                    {
                      title: 'Particular',
                      dataIndex: 'item_code',
                      render: (item_code, record) => (
                        <>
                          {formatFormalText(record.item_type)} {item_code}
                        </>
                      ),
                    },
                    {
                      title: 'Debit',
                      dataIndex: 'debit',
                      align: 'right',
                      render: value => (value > 0 ? formatCurrency(value) : ''),
                    },
                    {
                      title: 'Credit',
                      dataIndex: 'credit',
                      align: 'right',
                      render: value => (value > 0 ? formatCurrency(value) : ''),
                    },
                    {
                      title: 'Balance',
                      dataIndex: 'balance',
                      align: 'right',
                      render: value => formatCurrency(value),
                    },
                  ]}
                  dataSource={dataSource}
                  totalItems={paging?.total_items}
                  currentPage={paging?.current_page}
                  defaultCurrent={1}
                  defaultPageSize={paging?.page_size || paging?.per_page}
                  pageSize={paging?.page_size || paging?.per_page}
                />

                <hr className="mt-5" />

                {currentBalance ? (
                  <div className="flex justify-between items-center">
                    <p>
                      {`Ringgit Malaysia ${toWords.convert(currentBalance)} Only`.toUpperCase()}
                    </p>
                    <p className="pr-4">{formatCurrency(currentBalance)}</p>
                  </div>
                ) : null}

                <DataTable
                  rowKey="id"
                  columns={[
                    {
                      title: 'Current',
                      dataIndex: 'current',
                      align: 'center',
                      render: formatCurrency,
                    },
                    {
                      title: '1 Month',
                      dataIndex: '_1_month',
                      align: 'center',
                      render: formatCurrency,
                    },
                    {
                      title: '2 Months',
                      dataIndex: '_2_month',
                      align: 'center',
                      render: formatCurrency,
                    },
                    {
                      title: '3 Months',
                      dataIndex: '_3_month',
                      align: 'center',
                      render: formatCurrency,
                    },
                    {
                      title: '4 Months',
                      dataIndex: '_4_month',
                      align: 'center',
                      render: formatCurrency,
                    },
                  ]}
                  dataSource={balanceSummary}
                  totalItems={1}
                  currentPage={1}
                  defaultCurrent={1}
                />
                <Text>
                  We shall be gratefull if you will let us have payment as soon
                  as possible. Any discrepancy in this statement must be
                  reported to us in writing within 10 days.
                </Text>
              </div>
            </div>
          </div>
        )}
      </Card>
    </>
  );
};

export const CustomerStatementPrintPage = props => {
  const { user_id } = useParams();

  return (
    <DetailPage
      title="Statement"
      namespace="customers"
      apiCall={userApiCall}
      ItemDetail={StatementItemDetail}
      resourceName="items"
      options={{ id: user_id }}
      {...props}
    />
  );
};
